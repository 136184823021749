import styled from 'styled-components';
import media from '../../shared/media';

export const Wrapper = styled.div`
  margin-top: 20px;

  /* ${media.tablet} {
    margin-top: 100px;
  }

  ${media.desktop} {
    margin-top: 120px;
  } */

  &.no-media {
    margin-top: 20px;

    ${media.tablet} {
      margin-top: 30px;
    }

    ${media.desktop} {
      margin-top: 40px;
    }
  }
`;

export const Background = styled.div`
  padding: 20px 0;

  ${media.tablet} {
    padding: 30px 0;
  }

  ${media.desktop} {
    padding: 40px 0;
  }

  &.bg-dark {
    background: #000;

    & * {
      color: #fff;
    }
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: stretch;

  ${media.tablet} {
    flex-direction: row;
  }
`;

export const ContentWrapper = styled.div`
  ${media.tablet} {
    .text-image-alignment-left & {
      order: 1;
    }
    .text-image-alignment-right & {
      order: 0;
    }
  }
`;

export const Content = styled.div`
  margin: 24px 0 0 0;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  color: ${({theme}) => theme.colors.textColor};
  margin-top: 24px;

  ${media.tablet} {
    font-size: 20px;
    line-height: 28px;
  }

  p {
    margin: 0;
  }
`;

export const ActionLink = styled.div`
  margin-top: 24px;
`

export const Image = styled.div`
  flex-basis: 40%;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
  }

  img.svg-icon {
    width: auto;

    .theme-dark & {
      filter: invert(1);
      opacity: 0.8;
    }
  }

  ${media.tablet} {
    .text-image-alignment-left & {
      order: 0;
    }
    .text-image-alignment-right & {
      order: 1;
    }
  }
`;
